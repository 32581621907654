import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@material-ui/core';

export const GlobalStyle = (theme: Theme): SerializedStyles => css`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .headroom-wrapper {
    height: fit-content !important;
    &.disabled .headroom {
      transform: unset !important;
    }
  }
  .pointer {
    cursor: pointer;
  }

  .MuiAlert-standard {
    &Error {
      color: ${theme.palette.error.main};
      background-color: ${theme.palette.error.dark};
    }
    &Success {
      color: ${theme.palette.success.main};
      background-color: ${theme.palette.success.dark};
    }
    &Warning {
      color: ${theme.palette.warning.main};
      background-color: ${theme.palette.warning.dark};
    }
  }
  .MuiFormControl-root {
    .MuiFormHelperText-root {
      color: ${theme.palette.text.secondary};
      &:not(.extra-help-message) {
        color: ${theme.palette.warning.main};
      }
    }
    &:focus {
      border-color: ${theme.palette.neutral.charcoal100};
    }
  }

  .MuiInputBase-input,
  .MuiFilledInput-root {
    padding-top: 0.5rem;
  }

  .MuiInputLabel-formControl.MuiInputLabel-root,
  .MuiInputLabel-root.MuiFormLabel-root {
    font-weight: bold;
    color: ${theme.palette.primary.main};
    position: relative;
    transform: none;
    transition: none;
    font-size: 0.8rem;
    &.Mui-disabled {
      color: ${theme.palette.primary.main};
      opacity: 0.38;
    }
    & .label-asterisk {
      vertical-align: sub;
      line-height: 0;
    }
  }

  .MuiSlider {
    &-root {
      color: ${theme.palette.primary.main};
      &.Mui-disabled {
        color: ${theme.palette.primary.main};
        opacity: 0.38;
      }
    }
    &-thumb {
      background-color: ${theme.palette.primary.main};
      &.Mui-disabled {
        background-color: ${theme.palette.primary.main};
      }
    }
  }

  .MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root.Mui-checked {
    color: ${theme.palette.primary.main};
  }

  .mui-checkbox-error > span > svg {
    color: ${theme.palette.warning.main};
  }

  ul.MuiList-root {
    li.MuiListItem-root.MuiButtonBase-root {
      &:hover,
      &.Mui-focusVisible {
        background-color: ${theme.palette.secondary.dark};
      }
      &.Mui-selected {
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.primary.main};
        font-weight: bold;
      }

      &.MuiListItem-divider {
        border-bottom: none;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: calc(100% - 2rem);
          border-bottom: solid 1px ${theme.palette.grey[600]};
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .MuiToggleButtonGroup-root {
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};
    button.MuiToggleButton-root {
      border-color: transparent;
      border-radius: 0;
      background-color: ${theme.palette.grey[400]};
      padding: ${theme.spacing(1 / 3)};
      &.Mui-disabled,
      &[disabled] {
        opacity: 0.8;
      }
    }
  }

  .MuiPaper-root.MuiSelectPopover {
    border: solid 1px ${theme.palette.neutral.charcoal19};
    border-top: none;
    border-radius: 0;
    margin-top: 0.75rem;
    ul {
      padding: 0;
      li {
        padding: 1rem;
      }
    }
  }

  .MuiAccordion-root {
    box-shadow: none;
    &.Mui-expanded {
      margin-bottom: 0.5rem;
    }
    &:after {
      position: absolute;
      left: 0;
      top: -1px;
      right: 0;
      height: 1px;
      opacity: 1;
      content: '';
      background-color: ${theme.palette.grey[400]};
    }
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    padding-right: 1.5rem;
    &.Mui-expanded {
      margin-top: 3.5rem;
      margin-bottom: 3rem;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0.5rem 0 3rem 0;
  }

  .MuiAccordionSummary-expandIconWrapper {
    margin-bottom: 2rem;
  }

  ${theme.breakpoints.down('sm')} {
    .MuiAccordionSummary-expandIconWrapper {
      margin-bottom: 5.25rem;
    }
  }

  ${theme.breakpoints.between('sm', 'md')} {
    .MuiAccordionSummary-expandIconWrapper {
      margin-bottom: 4rem;
    }
  }

  ${theme.breakpoints.down('sm')} {
    main .MuiContainer-root {
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
    }
  }

  ${theme.breakpoints.between('sm', 'md')} {
    main .MuiContainer-root {
      padding-left: ${theme.spacing(10)};
      padding-right: ${theme.spacing(10)};
    }
  }

  ${theme.breakpoints.up('md')} {
    main .MuiContainer-root {
      padding-left: ${theme.spacing(15)};
      padding-right: ${theme.spacing(15)};
    }
  }
`;
