import * as React from 'react';
import { ComponentRoute } from '../interfaces';

export const routes: ComponentRoute[] = [
  {
    path: '/organizer',
    component: React.lazy(() => import('../pages/OrganizerPage/OrganizerPage')),
  },
  {
    path: '/funding-application',
    component: React.lazy(() => import('../pages/FundingApplicationPage/FundingApplicationPage')),
  },
  {
    path: '/partnership-application',
    component: React.lazy(
      () => import('../pages/PartnershipApplicationPage/PartnershipApplicationPage'),
    ),
  },
  {
    path: '/participant',
    component: React.lazy(() => import('../pages/ParticipantPage/ParticipantPage')),
  },
  {
    path: '/success',
    component: React.lazy(() => import('../pages/SuccessPage/SuccessPage')),
  },
];
