import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@material-ui/core';
import { Global } from '@emotion/react';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { GlobalStyle } from './design-system/theming/styles';
import { lightTheme } from './design-system/theming/light';
import { AppRouter } from './router';
import { Loading } from './design-system/atoms/Loading';

const App: React.FC = () => {
  const [theme] = React.useState(lightTheme);
  return (
    <Suspense fallback={<Loading theme={lightTheme} />}>
      <HelmetProvider>
        <Global styles={GlobalStyle(theme)} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </Suspense>
  );
};

export default App;
