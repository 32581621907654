import { createTheme, PaletteOptions } from '@material-ui/core';
import { GlobalStyle } from './styles';

declare module '@material-ui/core/styles' {
  interface Palette {
    neutral: {
      charcoal100: string;
      charcoal65: string;
      charcoal38: string;
      charcoal19: string;
      charcoal09: string;
      charcoal06: string;
      white100: string;
      white38: string;
      white08: string;
    };
    purple: {
      main: string;
      dark: string;
      light: string;
    };
  }
  interface PaletteOptions {
    neutral: {
      charcoal100: string;
      charcoal65: string;
      charcoal38: string;
      charcoal19: string;
      charcoal09: string;
      charcoal06: string;
      white100: string;
      white38: string;
      white08: string;
    };
    purple: {
      main: string;
      dark: string;
      light: string;
    };
  }
  interface PaletteColor {
    lighter?: string;
    lightest?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    lightest?: string;
  }
}
const lightThemePalette: PaletteOptions = {
  primary: {
    dark: 'rgba(46, 88, 235, 1)',
    main: 'rgba(13, 97, 255, 1)',
    light: 'rgba(13, 97, 255, 0.12)',
    lighter: 'rgba(13, 97, 255, 0.06)',
    lightest: 'rgba(13, 97, 255, 0.03)',
  },
  secondary: {
    dark: 'rgba(1, 102, 255, 0.10)',
    lighter: 'rgba(1, 102, 255, 0.03)',
    main: 'rgba(1, 102, 255, 0.12)',
    light: 'rgba(1, 102, 255, 0.06)',
    contrastText: 'rgba(1, 102, 255, 1)',
  },
  success: {
    main: 'rgba(42, 185, 0, 1)',
    light: 'rgba(42, 185, 0, 0.19)',
    lighter: 'rgba(0, 255, 0, 0.06)',
  },
  warning: {
    main: 'rgba(255, 100, 0, 1)',
    light: 'rgba(255, 100, 0, 0.19)',
    lighter: 'rgba(255, 100, 0, 0.06)',
  },
  error: {
    main: 'rgba(255, 0, 0, 1)',
    light: 'rgba(255, 0, 0, 0.19)',
    lighter: 'rgba(255, 0, 0, 0.06)',
  },
  neutral: {
    charcoal100: 'rgba(28, 30, 33, 1)',
    charcoal65: 'rgba(28, 30, 33, 0.65)',
    charcoal38: 'rgba(28, 30, 33, 0.38)',
    charcoal19: 'rgba(28, 30, 33, 0.19)',
    charcoal06: 'rgba(28, 30, 33, 0.06)',
    charcoal09: 'rgba(28, 30, 33, 0.09)',
    white100: 'rgba(255, 255, 255, 1)',
    white38: 'rgba(255, 255, 255, 0.38)',
    white08: 'rgba(255, 255, 255, 0.08)',
  },
  purple: {
    main: 'rgba(136, 58, 175, 1)',
    light: 'rgba(136, 58, 175, 0.12)',
    dark: 'rgba(136, 58, 175, 1)',
  },
  text: {
    primary: 'rgba(28, 30, 33, 1)',
    secondary: 'rgba(28, 30, 33, 0.65)',
    disabled: 'rgba(28, 30, 33, 0.38)',
  },
  background: {
    default: 'rgba(253, 253, 255, 1)',
    paper: 'rgba(255, 255, 255, 1)',
  },
  action: {
    disabled: 'rgba(255, 255, 255, 0.38)',
    disabledBackground: 'rgba(1, 102, 255, 1)',
  },
  tonalOffset: 0.05,
  mode: 'light',
};

export const lightTheme = createTheme({
  palette: lightThemePalette,
  typography: {
    fontWeightLight: 300,
    fontWeightMedium: 500,
    h1: {
      fontWeight: 300,
      fontSize: '3.4375rem',
      lineHeight: '1.3',
      '@media (max-width:900px)': {
        fontSize: '2.625rem',
      },
    },
    h2: {
      fontWeight: 300,
      fontSize: '2.625rem',
      lineHeight: '1.3',
    },
    h3: {
      fontWeight: 300,
      fontSize: '2.125rem',
      lineHeight: '1.3',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.625rem',
      lineHeight: '1.5',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.3125rem',
      lineHeight: '1.5',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '1.5',
    },
    body1: {
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: '1.5',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const light = GlobalStyle(lightTheme);
